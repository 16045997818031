import {TicketWithFormattedSalePeriod} from '@wix/wix-events-commons-statics'
import React from 'react'
import {LimitReachedNotification} from '../../fields/limit-reached-notification'
import {TicketContainer} from '../../ticket-container'
import s from './desktop-ticket.scss'
import {PriceQuantitySection} from './price-quantity-section'
import {TicketInfoSection} from './ticket-info-section'

export const DesktopTicket = ({ticket}: {ticket: TicketWithFormattedSalePeriod}) => {
  return (
    <TicketContainer innerContainerClassName={s.ticketContainer} ticket={ticket}>
      <LimitReachedNotification ticket={ticket} />
      <div className={s.ticketInnerContainer}>
        <div className={s.ticketInfoContainer}>
          <TicketInfoSection ticket={ticket} />
        </div>
        <div className={s.priceQuantityContainer}>
          <PriceQuantitySection ticket={ticket} />
        </div>
      </div>
    </TicketContainer>
  )
}
