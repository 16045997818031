import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {usePageStateSelector} from '../../../../hooks/state'
import {getTickets} from '../../../../selectors/tickets'
import {Footer} from '../footer'
import {Header} from '../header'
import {TicketsContainer} from '../tickets-container'
import {useCheckoutUnavailableCheck} from '../use-checkout-unavailable-check'
import {DesktopTicket} from './desktop-ticket'
import s from './desktop-tickets-picker.scss'

export const DesktopTicketsPicker = () => {
  useCheckoutUnavailableCheck()

  const tickets = usePageStateSelector(getTickets)

  if (!tickets.length) {
    return null
  }

  return (
    <div data-hook={DH.TICKETS_PICKER} id={DH.TICKETS_PICKER}>
      <Header />
      <TicketsContainer>
        {tickets.map(ticket => (
          <DesktopTicket key={ticket.id} ticket={ticket} />
        ))}
      </TicketsContainer>
      <div className={s.footerWrapper}>
        <div className={s.footerContainer}>
          <Footer />
        </div>
      </div>
    </div>
  )
}
