import {TicketWithFormattedSalePeriod} from '@wix/wix-events-commons-statics'
import React from 'react'
import {SaleEndsLabel} from '../../../fields/sale-ends-label'
import {TicketDescription} from '../../../fields/ticket-description'
import {TicketType} from '../../../fields/ticket-type'
import s from './ticket-info-section.scss'

export const TicketInfoSection = ({ticket}: {ticket: TicketWithFormattedSalePeriod}) => {
  return (
    <>
      <TicketType ticket={ticket} />
      <SaleEndsLabel ticket={ticket} className={s.saleEnds} />
      <TicketDescription ticket={ticket} className={s.description} />
    </>
  )
}
